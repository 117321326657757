import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';

const SubscribeForm = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError('Email is required');
      return;
    }
    setSubmitted(true);
    setError('');
    // Handle form submission logic here
    console.log('Email:', email);
    console.log('Phone:', phone);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      {submitted ? (
        <Typography variant="h6" color="primary">Thank you for subscribing!</Typography>
      ) : (
        <>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!error}
            helperText={error}
            InputLabelProps={{
              style: { color: '#000' },
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="phone"
            label="Phone Number"
            type="phone"
            id="phone"
            autoComplete="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            InputLabelProps={{
              style: { color: '#000' },
            }}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3 }}>
            Subscribe
          </Button>
        </>
      )}
    </Box>
  );
};

export default SubscribeForm;