import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Blog from './components/Blog';
import SubscribeForm from './components/SubscribeForm';
import theme from './styles/theme';
import './App.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md" style={{ textAlign: 'center', marginTop: '50px' }}>
        <Typography variant="h2" color="primary" gutterBottom>
          PLANTPAL
        </Typography>
        <Blog />
        <Container maxWidth="sm" style={{ marginTop: '50px' }}>
          <Typography variant="h4" color="primary" gutterBottom>
            Subscribe for Updates
          </Typography>
          <SubscribeForm />
        </Container>
      </Container>
    </ThemeProvider>
  );
}

export default App;