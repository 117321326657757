import React from 'react';
import { Container, Typography, Card, CardContent, CardMedia } from '@mui/material';
import missionImage from '../assets/mission.webp'; // Adjust the path as necessary

const Blog = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: '50px' }}>
      <Card>
        <CardMedia
          component="img"
          alt="Our Mission"
          height="400"
          image={missionImage}
          title="Our Mission"
        />
        <CardContent>
          <Typography variant="h4" color="primary" gutterBottom>
            Our Mission at PLANTPAL
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Welcome to PLANTPAL! We're excited to share our journey with you as we revolutionize the way people interact with and care for their plants. Our mission is to empower plant enthusiasts of all levels with the tools and knowledge they need to successfully grow and maintain their plants.
          </Typography>
          <Typography variant="h6" color="primary" gutterBottom>
            What We Aim to Achieve
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            At PLANTPAL, our objectives are clear and focused:
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            <strong>Personalized Care:</strong> We provide tailored advice for each plant based on its specific needs. Our app leverages advanced AI to analyze plant data and deliver customized care instructions.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            <strong>Plant Identification:</strong> With our cutting-edge image recognition technology, PLANTPAL accurately identifies plants from photos, helping users understand their plants better.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            <strong>Disease Detection:</strong> Early detection of plant diseases is crucial. Our app identifies symptoms and suggests effective treatments to keep plants healthy.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            <strong>Community Engagement:</strong> We foster a community of plant lovers who can share tips, experiences, and support each other through our interactive platform.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            <strong>Sustainable Gardening:</strong> Promoting eco-friendly practices and sustainability is at the heart of what we do. PLANTPAL encourages green living and responsible gardening.
          </Typography>
          <Typography variant="h6" color="primary" gutterBottom>
            Growing Together
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            We are thrilled to embark on this journey and grow together with our users. PLANTPAL is dedicated to making plant care accessible and enjoyable for everyone. Stay tuned for more updates and features as we continue to develop and enhance our app.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Thank you for being a part of the PLANTPAL community. Together, let's nurture and grow a greener world.
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Blog;